
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, EffectCards, Pagination],
        };
    },
};

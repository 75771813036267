<template>
    <router-view />
</template>

<style lang="less">
html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
</style>
